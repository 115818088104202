const initialState = { latestResponse: null }

const sessionReducer = (state = initialState, action) => {
  if (action.type.startsWith('FETCHED_') || action.type.startsWith('POSTED_') || action.type === 'USER_IS_TIMED_OUT') {
    // Update the browser store with the latest response to keep session timeout values in sync across windows
    window.localStorage.setItem('latestResponse', new Date())
    // This bit of state doesn't do anything, it's just informational
    return { latestResponse: new Date() }
  }
  return state
}

export default sessionReducer
