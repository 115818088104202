const initialErrorState = {
  errors: []
}

const errorReducer = function (previousErrorState = initialErrorState, action) {

  // TODO: unify console logging of requestuuid (e.g. move to errorActions.js)
  if (action && action.error && action.error.lgyRequestUuid) {
    console.log('lgyRequestUuid is ' + action.error.lgyRequestUuid)
  }

  switch (action.type) {
    // TODO: Merge CAUGHT_API_ERROR and ADD_ERROR?
    // TODO: Ignorable is overriden to true here to let the gears handle all of the UI of an error - this is something we will want to address later but should reduce bugs for now
    case 'CAUGHT_API_ERROR':
      return {
        ...previousErrorState,
        errors: [...previousErrorState.errors, { dismissed: false, ...action.response, ignorable: true }]
      }
    case 'ADD_ERROR':
      return {
        ...previousErrorState,
        errors: [...previousErrorState.errors, { dismissed: false, ignorable: false, ...action.error }]
      }
    case 'DISMISS_ERROR':
      return {
        ...previousErrorState,
        errors: previousErrorState.errors.map(e => ({ ...e, dismissed: true }))
      }
    default:
      if (action.type.includes('ERROR_FETCHING_') || action.type.includes('ERROR_POSTING_')) {
        // TODO: Ignorable is overriden to true here to let the gears handle all of the UI of an error - this is something we will want to address later but should reduce bugs for now
        const newError = { dismissed: false, ...action.error, ignorable: true }
        return {
          ...previousErrorState,
          errors: [...previousErrorState.errors, newError]
        }
      }
      return previousErrorState
  }

}

export default errorReducer
