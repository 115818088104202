import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import AppContainer from 'app/components/AppContainer.jsx'
import lgyStore from 'app/store/lgyStore'

render(
  <Provider store={lgyStore}>
    <AppContainer />
  </Provider>,
  document.getElementById('app')
)
