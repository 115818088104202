import { combineReducers } from 'redux'
import { createReducer, ActionTypes } from 'redux-plupload'
import { genericReducer } from 'app/reducers/genericReducerCreator'

const pluploadReducer = (state, action) => {
  state = createReducer('default')(state, action)
  switch (action.type) {
    case ActionTypes.FILES_ADDED: {
      return !action.payload[0].size ? {
        ...state,
        error: 'File size error.'
      } : state
    }
    case ActionTypes.ERROR: {
      return { 
        ...state, 
        error: action.payload.message
      }
    }
    default:
      return state
  }
}

export const parentOversightReducer = combineReducers({
  auditSearch: genericReducer('AUDIT_SEARCH'),
  auditRecord: genericReducer('AUDIT_RECORDS'),
  activeAudits: genericReducer('ACTIVE_AUDITS'),
  correspondence: genericReducer('AUDIT_CORRESPONDENCE'),
  uploader: pluploadReducer
})

