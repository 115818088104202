import { createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import rootReducer from '../reducers/rootReducer'
import logger from 'redux-logger'
import { createMiddleware as createUploaderMiddleware } from 'redux-plupload';
import plupload from 'plupload'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

const enableLocalStorageState = false
let lgyState = {}

if (enableLocalStorageState && window.localStorage.getItem('lgyState')) {
  try {
    lgyState = JSON.parse(window.localStorage.getItem('lgyState')) //TODO: redux-store?
    console.log('found pre-existing state in localStage, hydrating with ', lgyState)
  } catch (error) {
    console.error(error)
  }
}

//This is for the redux-plupload library 
const uploaderMiddleware = createUploaderMiddleware(plupload);

// Redux Logger + Redux DevTools Extension (devtools extension in local only)
const middlewares = [ReduxThunk, logger, uploaderMiddleware]
const middlewareEnhancer = applyMiddleware(...middlewares)
const composedEnhancers = composeWithDevTools(middlewareEnhancer)
const lgyStore = createStore(rootReducer, lgyState, composedEnhancers)


if (enableLocalStorageState) {
  lgyStore.subscribe(() => {
    window.localStorage.setItem('lgyState', JSON.stringify(lgyStore.getState()))
  })
}

export default lgyStore
