import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { genericReducer } from './genericReducerCreator'
import alertReducer from 'app/gears/alert/reducers/alertReducer'
import errorReducer from 'app/gears/errorhandler/reducers/errorReducer'
import { parentAssignmentReducer as assignments } from 'app/gears/appraiser/assignment/reducers/parentAssignmentReducer'
import { parentWorkspaceReducer as workspace } from 'app/gears/appraiser/workspace/reducers/parentWorkspaceReducer'
import condoBuilderReducer from 'app/gears/condo-builder/reducers/condoBuilderReducer'
import parentProfileReducer from 'app/gears/profile/reducers/parentProfileReducer'
import userReducer from './userReducer'
import sessionReducer from './sessionReducer'
import adminReducer from 'app/gears/admin/reducers/adminReducer'
import announcementsReducer from 'app/gears/announcements/reducers/announcementsReducer'
import jobReducer from 'app/gears/admin/jobs/reducers/jobReducer'
import { parentOversightReducer as oversightReducer } from 'app/gears/oversight/reducers/parentOversightReducer'
import guarantyCalculatorReducer from 'app/gears/guarantycalculator/reducers/guarantyCalculatorReducer'
import fundingfeeReducer from 'app/gears/funding-fee/reducers/fundingfeeReducer'
import chunkedUploadsReducer from 'app/reducers/chunkedUploadsReducer'
import lenderReducer from 'app/gears/lender/reducers/lenderReducer'
import traceReducer from './traceReducer'
import ffpsUserDetailsReducer from './ffpsUserDetailsReducer'


const rootReducer = combineReducers({
  vetInfo: genericReducer('VIS'),
  alert: alertReducer,
  currentUser: userReducer,
  serverProperties: genericReducer('SERVER_PROPERTIES'),
  trainingUrl : genericReducer("TRAINING_URL"),
  errorState: errorReducer,
  appraiser: combineReducers({ workspace, assignments }),
  homeBuilder: condoBuilderReducer('HOMEBUILDER'),
  condoReport: condoBuilderReducer('CONDOREPORT'),
  profileInfo: parentProfileReducer,
  form: formReducer,
  faqState: genericReducer('FAQ'),
  faqCategoryState: genericReducer('FAQCategory'),
  session: sessionReducer,
  stations: genericReducer('STATION_LIST'),
  adminState: adminReducer,
  announcementsState: announcementsReducer,
  jobState: jobReducer,
  reactivationForm: genericReducer('REACTIVATE_LENDER_SERVICER'),
  oversight: oversightReducer,
  guarantyCalculatorState: guarantyCalculatorReducer,
  nadlRecords: genericReducer('NADLRecords'),
  fundingFee: fundingfeeReducer,
  chunkedUploads: chunkedUploadsReducer,
  lender: lenderReducer,
  trace: traceReducer,
  ffpsUserDetail: ffpsUserDetailsReducer
})

export default rootReducer
