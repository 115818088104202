/**
 * Reducer for handling request information (trace ID) for reporting purposes
 *
 * @prettier
 * @author Alexander Nagelberg
 * @since 24.1
 */
//@ts-check
import { AnyAction } from 'redux'
import { genericReducer } from 'app/reducers/genericReducerCreator'

export interface ITraceStateData {
  status?: number
  responseId?: string
}

export interface ITraceState extends ITraceStateData {
  fetching: boolean
}

export enum TraceActionType {
  FetchingTrace = 'FETCHING_TRACE',
  FetchedTrace = 'FETCHED_TRACE'
}

export interface TraceAction extends AnyAction {
  type: TraceActionType
  data: ITraceStateData
}

const traceReducer = (state: any, action: TraceAction): ITraceState => {
  state = genericReducer('TRACE')(state, action)
  switch (action.type) {
    case TraceActionType.FetchingTrace:
      return {
        ...state,
        fetching: true,
        status: undefined,
        responseId: undefined
      }
    case TraceActionType.FetchedTrace:
      return {
        ...state,
        fetching: false,
        ...state.data
      }
    default:
      return {
        ...state
      }
  }
}

export default traceReducer
