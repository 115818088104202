import { genericReducer } from '../../../reducers/genericReducerCreator'

const condoBuilderReducer = (name) => (state = {}, action) => {
  state = genericReducer(name)(state, action)
  switch (action.type) {
    case `NEW_SEARCH_${name}`:
      return {
        ...state,
        list: null
      }
    default:
      return state
  }
}

export default condoBuilderReducer