import { genericFetchingActionCreator } from './genericActionCreator'
import { catchCommonErrors } from './commonActionUtils'
import { addError } from 'app/gears/errorhandler/actions/errorActions'

export const fetchCurrentUser = () => {
  return (dispatch) => {
    dispatch(genericFetchingActionCreator('CURRENT_USER', 'fetching'))
    fetch('/lgyhub/api/users/me', {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      return catchCommonErrors(dispatch, response, 'finding your user')
    }).then((responseJson) => {
      if (responseJson.status >= 400) {
        dispatch(addError({
          error: 'User Not Found',
          message: 'We are unable to locate your user at this time. Please try again later and submit a ticket if the problem persists.',
          ignorable: false,
          shouldDisplayHelpLink: true
        }))
        dispatch(genericFetchingActionCreator('CURRENT_USER', 'error', responseJson))
        throw new Error(responseJson.message)
      } else {
        let user = responseJson
        if (user.vaEauthSecId) {
          dispatch(genericFetchingActionCreator('CURRENT_USER', 'fetched', user))
        } else {
          dispatch({ type: 'USER_IS_ANONYMOUS' })
        }
      }
    }).catch((error) => {
      console.error('There has been a problem fetching the current user:', error)
    })
  }
}

export const userSessionHeartbeat = () => {
  return (dispatch) => {
    dispatch(genericFetchingActionCreator('HEARTBEAT', 'fetching'))
    fetch('/lgyhub/api/heartbeat', {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      return catchCommonErrors(dispatch, response, 'continuing your session')
    }).then((response) => {
      dispatch(genericFetchingActionCreator('HEARTBEAT', 'fetched', response))
    }).catch((error) => {
      console.error('There has been a problem with session heartbeat:', error)
    })
  }
}
