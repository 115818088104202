import { genericFetchingActionCreator } from './genericActionCreator'
import { catchCommonErrors } from './commonActionUtils'

export const fetchServerProperties = () => {
  return (dispatch) => {
    dispatch(genericFetchingActionCreator('SERVER_PROPERTIES', 'fetching'))
    fetch('/lgyhub/api/properties', { credentials: 'include' })
      .then((response) => {
        return catchCommonErrors(dispatch, response, 'finding your user')
      }).then((data) => {
        dispatch(genericFetchingActionCreator('SERVER_PROPERTIES', 'fetched', data))
      }).catch((error) => {
        dispatch(genericFetchingActionCreator('SERVER_PROPERTIES', 'error'))
        console.error('There has been a problem fetching the server properties: ', error)
      })
  }
}

export const fetchTrainingUrl = () => {
  return (dispatch) => {
      dispatch(genericFetchingActionCreator('TRAINING_URL', 'fetching'))
      fetch('/lgyhub/api/enterprisesettings/trainingurl', {
          credentials: 'include',
          method: 'GET'
      }).then((response) => {
          return catchCommonErrors(dispatch, response, 'fetching enterprise settings')
      }).then((responseJson) => {
          if (responseJson.status >= 400) {
              dispatch(genericFetchingActionCreator('TRAINING_URL', 'error'))
              throw new Error(responseJson.errors[0].message)
          } else {
              dispatch(genericFetchingActionCreator('TRAINING_URL', 'fetched', responseJson ))
          }
      }).catch((error) => {
          console.error('Enterprise settings not found: ', error)
      })
  }
}
