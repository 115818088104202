import { genericReducer } from './genericReducerCreator'

const initialState = {
  accountStatusCode: null,
  active: null,
  affiliations: [],
  authorizations: [],
  birthDate: null,
  email: null,
  entities: [],
  error: false,
  exists: false,
  isAffiliate: null,
  fetching: false,
  firstName: null,
  id: null,
  jobTitle: null,
  lastLoginDate: null,
  lastName: null,
  middleName: null,
  newUser: false,
  portalId: null,
  posting: false,
  primaryPhoneNumber: null,
  primaryPhoneNumberExtension: null,
  primaryPhoneNumberType: null,
  registered: false,
  secondaryPhoneNumber: null,
  secondaryPhoneNumberExtension: null,
  secondaryPhoneNumberType: null,
  ssn: null,
  suffixName: null,
  userType: null,
  vaEauthSecId: null
}

const userReducer = (state = initialState, action) => {
  state = genericReducer('CURRENT_USER')(state, action)
  switch (action.type) {
    case 'USER_IS_ANONYMOUS':
      return {
        ...state,
        fetching: false,
        exists: false
      }
    case 'FETCHED_CURRENT_USER':
      return {
        ...state,
        exists: true
      }
    case 'POSTED_UPDATE_USER_AFFILIATIONS':
      return {
        ...state,
        newUser: action.data.newUser,
        affiliations: action.data.affiliations
      }
    case 'USER_IS_TIMED_OUT':
      return initialState
    default:
      return state
  }
}

export default userReducer