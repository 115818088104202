import { addError, catchTimeoutError, addWebpackBackendNotFoundError } from '../gears/errorhandler/actions/errorActions'
import { SubmissionError } from 'redux-form'
import { convertLgyErrorResponseToFormErrorObject } from 'app/utils/formUtils'

export const hasContentType = (contentType, response) => response && response.headers && response.headers.get('content-type') && response.headers.get('content-type').includes(contentType)

export const isJson = (response) => hasContentType('application/json', response)

export const isHtml = (response) => hasContentType('text/html', response)

export const isVaafiLoginPage = (response, text) => isHtml(response) && text.includes('VAAFI Login')

export const isWebpackDevCannotConnectToApiBackend = (response, text) => response.status === 504 && text.includes('Error occured while trying to proxy to')

// TODO: put this in some util file or use some fancy-schmancy thing like react-cookie
export const getCookieValue = (a) => {
  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : null;
}

/**
 * Used to fetch data in a single line
 * 
 * @author Alex Clary
 * 
 * @param {*} name the name of the resource (used for issuing redux actions)
 * @param {*} url the URL to fetch
 * @param {*} fetchOptions optional object to override fetch params
 * @param {function} responseHandler optional function to manipulate data after a fetch before putting into redux
 * 
 * TODO: Replace this with some other approach TBD
 */
export const genericThunkCreator = (name, url, fetchOptions, responseHandler) => (dispatch, getState) => {
  console.log('genericThunkCreator executing')
  const requestType = (fetchOptions && fetchOptions.method === 'POST') ? 'POST' : 'FETCH'
  dispatch({ type: `${requestType}ING_${name}` })
  return fetch(url, { //TODO: add return
    // default options
    credentials: 'include',
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getCookieValue('XSRF-TOKEN')
    }),
    ...fetchOptions
  }).then((response) => {
    if (response.ok && isJson(response)) {
      return response.json()
    }
    if (!response.ok) {
      // response must be cloned because .text() will empty the stream buffer, causing another .json or .text call to fail
      response.clone().text().then((text) => {
        if (isJson(response)) {
          response.json().then(jsonResponse => {
            dispatch({ type: `ERROR_${requestType}ING_${name}`, error: jsonResponse })
            dispatch(addError(jsonResponse))
          })
        } else if (isVaafiLoginPage(response, text)) {
          dispatch(catchTimeoutError())
        } else if (isWebpackDevCannotConnectToApiBackend(response, text)) {
          console.log('response', response)
          dispatch(addWebpackBackendNotFoundError())
        } else {
          const error = { error: 'Unknown error', message: 'An unknown error occurred' }
          dispatch({ type: `ERROR_${requestType}ING_${name}`, error })
          dispatch(addError(error))
        }
        console.error(name + ' fetching error: ', text)
      })
      console.error('Invalid response details: ', response)
      throw new Error('Response from ' + response.url + ' was not OK')
    }
    throw new Error('Response was OK but supplied an unknown response format')
  }).then((response) => {
    dispatch({ type: `${requestType}ED_${name}`, data: responseHandler ? responseHandler(response, dispatch, getState) : response })
  }).catch((error) => {
    console.error(name + ' fetching error: ', error)
  })
}

/**
 * Called "genericThunkCreator2" so the previous one can be left alive for backwards compatibility
 * @param {*} name 
 * @param {*} url 
 * @param {*} fetchOptions 
 * @param {*} responseHandler 
 */
export const genericThunkCreator2 = (name, url, fetchOptions, responseHandler) => (dispatch, getState) => {
  console.log('Request for ' + name + ' for ' + url + ' with options ', fetchOptions)
  const requestType = (fetchOptions && fetchOptions.method === 'POST') ? 'POST' : 'FETCH'
  const GENERIC_EXCEPETION_MESSAGE = 'An unexpected error occurred'
  dispatch({ type: `${requestType}ING_${name}` })
  return fetch(url, {
    // default options
    credentials: 'include',
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getCookieValue('XSRF-TOKEN')
    }),
    ...fetchOptions,
    body: (fetchOptions && fetchOptions.body) ? JSON.stringify(fetchOptions.body) : undefined
  }).then((response) => {

    if (response.ok && isJson(response)) {
      return response.json().then(responseJson => {
        dispatch({ type: `${requestType}ED_${name}`, data: responseHandler ? responseHandler(responseJson, dispatch, getState) : responseJson })
      })
    } else if (response.ok && !isJson(response)) {
      return response.text().then((text) => {
        if (isVaafiLoginPage(response, text)) {
          dispatch(catchTimeoutError())
        }
      })
    } else if (!response.ok && !isJson(response)) {
      return response.text().then((text) => {
        if (isWebpackDevCannotConnectToApiBackend(response, text)) {
          dispatch(addWebpackBackendNotFoundError())
        } else {
          if (response.status >= 500) {
            dispatch(addError(GENERIC_EXCEPETION_MESSAGE))
          }
          dispatch({ type: `ERROR_${requestType}ING_${name}`, error: GENERIC_EXCEPETION_MESSAGE })
          // Throw a form compatible exception, mapping the errors to the form fields
          console.log('Throwing submission error')
          throw new SubmissionError({ _error: GENERIC_EXCEPETION_MESSAGE })
        }
      })
    } else {//if (!response.ok && isJson(response)) 
      return response.json().then(responseJson => {
        if (responseJson.errors) {
          if (response.status >= 500) {
            dispatch(addError(responseJson.errors[0]))
          }
          dispatch({ type: `ERROR_${requestType}ING_${name}`, error: responseJson.errors[0] })
          // Throw a form compatible exception, mapping the errors to the form fields
          console.log('Throwing submission error')
          throw new SubmissionError(convertLgyErrorResponseToFormErrorObject(responseJson))
        } else {
          dispatch(addError(GENERIC_EXCEPETION_MESSAGE))
        }
      })
    }
  }).finally(() => {
    // Update the browser store with the latest response to keep session timeout values in sync across windows
    window.localStorage.setItem('latestResponse', new Date())
  })
}

export const genericFetchingActionCreator = (name, action, data) => {
  switch (action) {
    case 'fetching':
      return {
        type: `FETCHING_${name}`,
        data
      }
    case 'fetched':
      return {
        type: `FETCHED_${name}`,
        data
      }
    case 'error':
      return {
        type: `ERROR_FETCHING_${name}`,
        error: data ? data : { error: true }
      }
    case 'init':
      return {
        type: `GET_INITIAL_STATE_${name}`
      }
    default:
  }
}

export const genericPostingActionCreator = (name, action, data) => {
  switch (action) {
    case 'posting':
      return {
        type: `POSTING_${name}`
      }
    case 'posted':
      return {
        type: `POSTED_${name}`,
        data: data ? data : { success: true }
      }
    case 'error':
      return {
        type: `ERROR_POSTING_${name}`,
        error: data ? data : { error: true }
      }
    case 'init':
      return {
        type: `GET_INITIAL_STATE_${name}`
      }
    default:
  }
}
