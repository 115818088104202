//import this to dispatch errors to error handler
export const catchApiErrors = (response, gear) => {
  return ({
    type: 'ADD_ERROR',
    error: {
      ...response,
      error: 'An Unexpected Error Occurred',
      message: gear ? `There was an unexpected error with ${gear}.` : 'Oops!',
      ignorable: false,
      shouldDisplayHelpLink: true,
      lgyRequestUuid: response.headers.get('x-lgyrequestuuid') ? response.headers.get('x-lgyrequestuuid').split(',')[0] : null
    }
  })
}

export const catchTimeoutError = () => ({
  type: 'ADD_ERROR',
  error: {
    error: 'Session Timeout',
    message: 'Your session has timed out due to inactivity. Please sign in again to continue.',
    ignorable: false,
    shouldDisplayHelpLink: false
  }
})

export const addWebpackBackendNotFoundError = () => ({
  type: 'ADD_ERROR',
  error: {
    error: 'Cannot connect to backend',
    message: 'Backend server is not running.',
    ignorable: false,
    shouldDisplayHelpLink: false
  }
})

export const addError = (error) => ({
  type: 'ADD_ERROR',
  error
})

export const dismissErrors = () => ({
  type: 'DISMISS_ERROR'
})