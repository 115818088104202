const initialState = {
  fetching: false,
  posting: false,
  error: false
}

export const genericReducer = (name) => {
  return (state = initialState, action) => {
    let newState
    switch (action.type) {
      case `FETCHING_${name}`:
        newState = {
          ...initialState,
          ...state,
          fetching: true,
          error: false
        }
        return newState
      case `FETCHED_${name}`:
        newState = {
          ...initialState,
          ...state,
          ...action.data,
          fetching: false
        }
        return newState
      case `ERROR_FETCHING_${name}`:
        newState = {
          ...initialState,
          ...state,
          ...action.error,
          fetching: false,
          error: true
        }
        return newState
      case `POSTING_${name}`:
        newState = {
          ...initialState,
          ...state,
          posting: true
        }
        return newState
      case `POSTED_${name}`:
        newState = {
          ...initialState,
          ...state,
          ...action.data,
          posting: false
        }
        return newState
      case `ERROR_POSTING_${name}`:
        newState = {
          ...initialState,
          ...state,
          ...action.error,
          posting: false,
          error: true
        }
        return newState
      case `GET_INITIAL_STATE_${name}`:
        newState = {
          ...initialState
        }
        return newState
      default:
        return state
    }
  }
}
