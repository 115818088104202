/**
 * @prettier
 * @author Alexander Nagelberg
 * @since 24.3
 */
//@ts-check
import { AnyAction } from 'redux'
import { ISettingsPage } from '../types/ISettingsPage'

export interface ISettingsState {
  fetching: boolean
  posting: boolean
  error: boolean
  success: boolean
  posted: boolean
  pages: Array<ISettingsPage>
  currentPage?: ISettingsPage
}

export enum SettingsActionType {
  FetchingSettings = 'FETCHING_SETTINGS',
  FetchedPages = 'FETCHED_SETTINGS_PAGES',
  FetchedPage = 'FETCHED_SETTINGS_PAGE',
  PostingSettings = 'POSTING_SETTINGS',
  PostedSettings = 'POSTED_SETTINGS',
  InitPage = 'INIT_SETTINGS_PAGE',
  ErrorPostingSettings = 'ERROR_POSTING_SETTINGS'
}

export interface SettingsAction extends AnyAction {
  type: SettingsActionType
  data: Array<ISettingsPage> | ISettingsPage
}

const initialState = {
  fetching: false,
  posting: false,
  error: false,
  posted: false,
  pages: [],
  currentPage: undefined
}

export const settingsReducer = (state = initialState, { type, data }: SettingsAction) => {
  switch (type) {
    case SettingsActionType.FetchingSettings:
      return {
        ...state,
        fetching: true,
        success: false,
        error: false
      }
    case SettingsActionType.FetchedPage:
      return {
        ...state,
        fetching: false,
        success: true,
        error: false,
        currentPage: data
      }
    case SettingsActionType.FetchedPages:
      return {
        ...state,
        fetching: false,
        success: true,
        error: false,
        pages: data
      }
    case SettingsActionType.PostingSettings:
      return {
        ...state,
        posting: true,
        posted: false,
        success: false,
        error: false
      }
    case SettingsActionType.PostedSettings:
      return {
        ...state,
        currentPage: data,
        posting: false,
        posted: true,
        success: true,
        error: false
      }
    case SettingsActionType.InitPage:
      return {
        ...state,
        currentPage: undefined,
        fetching: false,
        posting: false,
        posted: false,
        error: false
      }
    case SettingsActionType.ErrorPostingSettings:
      return {
        ...state,
        posting: false,
        posted: true,
        success: false,
        error: true
      }
    default:
      return state
  }
}
