/**
 * @prettier
 * @author Alexander Nagelberg
 * @since 24.3
 */
//@ts-check
import { AnyAction } from 'redux'
import { IFundingFee } from '../types/IFundingFee'

export interface IFundingFeeState {
  fetching: boolean
  posting: boolean
  error: boolean
  success: boolean
  data: IFundingFee
}

export enum FundingFeeActionType {
  PostingFundingFee = 'POSTING_FUNDING_FEE',
  FetchingLenderWorkspace = 'FETCHING_LENDER_WORKSPACE',
  FetchingFundingFee = 'FETCHING_FUNDING_FEE',
  PostedFundingFee = 'POSTED_FUNDING_FEE',
  FetchedFundingFee = 'FETCHED_FUNDING_FEE'
}

export interface FundingFeeAction extends AnyAction {
  type: FundingFeeActionType
  data: IFundingFee
}

const initialState = {
  fetching: false,
  posting: false,
  error: false
}

export const fundingFeeReducer = (state = initialState, action: FundingFeeAction) => {
  switch (action.type) {
    case FundingFeeActionType.PostingFundingFee:
      return {
        ...state,
        posting: true,
        success: false,
        error: false
      }
    case FundingFeeActionType.FetchingLenderWorkspace:
    case FundingFeeActionType.FetchingFundingFee:
      return {
        ...state,
        fetching: true,
        success: false,
        error: false
      }
    case FundingFeeActionType.PostedFundingFee:
      return {
        ...state,
        data: action.data,
        posting: false,
        success: true
      }
    case FundingFeeActionType.FetchedFundingFee: {
      return {
        ...state,
        data: action.data,
        fetching: false
      }
    }
    default:
      return state
  }
}
