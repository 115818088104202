/**
 *
 * @prettier
 * @author Brook Worku
 * @since
 *
 * reducer to update ffps user details information
 */
import { IAction } from 'app/gears/admin/settings/types/FfpsUserDetailsTypes'
enum FfpsActions {
  FetcFfpsUserDetails = 'UPDATE_FFPS_USER_DETAILS',
  FetchingFfpsUserDetails = 'FETCHING_FFPS_USER_DETAILS',
  ErrorFetchingFfpsUser = 'ERROR_FETCHING_FFPS_USER',
  ResetFfpsUserDetails = 'RESET_FFPS_USER_DETAILS'
}

const initialState = {
  fetching: false,
  fetched: false,
  error: false,
  ffpsUserFirstName: '',
  ffpsUserLastName: '',
  ffpsUserEmail: '',
  errorMsg: ''
}

const ffpsUserDetailsReducer = (
  state = initialState,
  { type, ffpsUserFirstName, ffpsUserLastName, ffpsUserEmail, errorMsg }: IAction
) => {
  switch (type) {
    case FfpsActions.FetcFfpsUserDetails:
      return {
        ...state,
        ffpsUserFirstName: ffpsUserFirstName,
        ffpsUserLastName: ffpsUserLastName,
        ffpsUserEmail: ffpsUserEmail,
        fetched: true,
        fetching: false,
        error: false,
        errorMsg: ''
      }

    case FfpsActions.FetchingFfpsUserDetails:
      return {
        ...state,
        fetching: true
      }

    case FfpsActions.ErrorFetchingFfpsUser:
      return {
        ...state,
        error: true,
        errorMsg: errorMsg,
        ffpsUserFirstName: '',
        ffpsUserLastName: '',
        ffpsUserEmail: '',
        fetching: false,
        fetched: false
      }

    case FfpsActions.ResetFfpsUserDetails:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default ffpsUserDetailsReducer
