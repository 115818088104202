import { genericPostingActionCreator, genericFetchingActionCreator, getCookieValue } from 'app/actions/genericActionCreator'
import { catchCommonErrors } from 'app/actions/commonActionUtils'
import { fetchCurrentUser } from 'app/actions/currentUserActions'


export const fetchAdminContacts = ({ affiliateId, affiliationType }) => {
  return (dispatch) => {
    dispatch(genericFetchingActionCreator('ADMIN_LIST', 'fetching'))
    return fetch(`/lgyhub/api/admins?affiliateId=${affiliateId}&affiliationType=${affiliationType}`).then((response) => {
      return catchCommonErrors(dispatch, response, 'fetching admin contacts')
    }).then((responseJson) => {
      if (responseJson.status >= 400) {
        dispatch(genericFetchingActionCreator('ADMIN_LIST', 'error', responseJson.errors[0].message))
      } else {
        dispatch(genericFetchingActionCreator('ADMIN_LIST', 'fetched', { admins: responseJson }))
      }
    }).catch((error) => {
      dispatch(genericFetchingActionCreator('ADMIN_LIST', 'error', error))
      console.error('Failed to fetch admin contacts: ', error)
    })
  }
}

export const clearProfileMessages = () => {
  return (dispatch) => {
    dispatch(genericPostingActionCreator('CURRENT_USER_PROFILE', 'init'))
  }
}

export const updateProfile = ({ email, primaryPhoneNumber, primaryPhoneNumberExtension, primaryPhoneNumberType,
  secondaryPhoneNumber, secondaryPhoneNumberExtension, secondaryPhoneNumberType, jobTitle, userStation, vaEauthSecId }) => {
  return (dispatch) => {
    dispatch(genericPostingActionCreator('CURRENT_USER_PROFILE', 'posting'))
    return fetch('/lgyhub/api/users/update/me', {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        email,
        primaryPhoneNumber: primaryPhoneNumber,
        primaryPhoneNumberExtension,
        primaryPhoneNumberType,
        secondaryPhoneNumber: secondaryPhoneNumber,
        secondaryPhoneNumberExtension,
        secondaryPhoneNumberType,
        jobTitle,
        userStation,
        vaEauthSecId
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': getCookieValue('XSRF-TOKEN')
      })
    }).then((response) => {
      const name = ''; // TOFIX: name is not defined -- putting placeholder here until author fixes
      return catchCommonErrors(dispatch, response, name)
    }).then((responseJson) => {
      if (responseJson.status >= 400) {
        // TODO: even if somehow a validation exception got past the frontend check(s) this isn't handled in the component, get rid of or handle?
        dispatch(genericPostingActionCreator('CURRENT_USER_PROFILE', 'error', responseJson.errors[0].message))
        throw new Error(responseJson.errors[0].message)
      } else { //success
        dispatch(genericPostingActionCreator('CURRENT_USER_PROFILE', 'posted'))
        dispatch(fetchCurrentUser())
      }
    }).catch((error) => {
      console.error('Profile failed to update: ', error)
    })
  }
}

export const fetchAllStations = () => {
  return (dispatch) => {
    dispatch(genericFetchingActionCreator('STATION_LIST', 'fetching'))
    return fetch('/lgyhub/api/stations', {
      credentials: 'include',
      method: 'GET'
    }).then((response) => {
      return catchCommonErrors(dispatch, response, 'the Station List')
    }).then((responseJson) => {
      if (responseJson.status >= 400) {
        dispatch(genericFetchingActionCreator('STATION_LIST', 'error', responseJson.errors[0].message))
        throw new Error(responseJson.errors[0].message)
      } else { //success
        dispatch(genericFetchingActionCreator('STATION_LIST', 'fetched', responseJson))
      }
    }).catch((error) => {
      console.error('Station List fetching error: ', error)
    })
  }
}

export const clearAffiliationMessages = () => {
  return (dispatch) => {
    dispatch(genericPostingActionCreator('UPDATE_USER_AFFILIATIONS', 'init'))
  }
}

export const updateAffiliations = (affiliations, vaEauthSecId) => {
  return (dispatch) => {
    dispatch(genericPostingActionCreator('UPDATE_USER_AFFILIATIONS', 'posting', { posting: true }))
    return fetch('/lgyhub/api/users/update/me/affiliations', {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({ affiliations, vaEauthSecId }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': getCookieValue('XSRF-TOKEN')
      })
    }).then((response) => {
      return catchCommonErrors(dispatch, response, 'updating your affiliations')
    }).then((responseJson) => {
      if (responseJson.status >= 400) {
        if (responseJson.status === 404) {
          dispatch(genericPostingActionCreator('UPDATE_USER_AFFILIATIONS', 'error', { error: true, message: 'User not found.' }))
        } else {
          dispatch(genericPostingActionCreator('UPDATE_USER_AFFILIATIONS', 'error', {
            error: true,
            message: responseJson.errors[0].message,
            invalidField: responseJson.errors[0].field
          }))
        }
        throw new Error(responseJson.errors[0].message)
      } else { //success
        dispatch(genericPostingActionCreator('UPDATE_USER_AFFILIATIONS', 'posted', { success: true, ...responseJson }))
        dispatch(genericFetchingActionCreator('CURRENT_USER', 'fetched', responseJson))
      }
    }).catch((error) => {
      console.error('Affiliations failed to update: ', error)
    })
  }
}
