const initialState = {
  submitting: false,
  submitFailed: false,
  submitSucceeded: false,
  countyLoanLimits: [{ "county": "default", "state": "default", "loanLimit": 0.00 }],
  stateSelected: '',
  countySelected: '',
  previousEntitlementCharged: '',
  loanAmount: '',
  guarantyAmount: undefined,
  guarantyPercent: undefined
}

const guarantyCalculatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case '@@redux-form/CHANGE':
      if (action.meta.form === 'guarantyCalculator') {
        return {...state, submitting: false, submitFailed: false, submitSucceeded: false, guarantyAmount: undefined, guarantyPercent: undefined }
      } else {
        return {...state}
      }
    case 'POSTING_ESTIMATE':
    case 'FETCHING_COUNTY_LOAN_LIMITS':
      return { ...state, submitting: true, submitFailed: false, submitSucceeded: false, guarantyAmount: undefined, guarantyPercent: undefined }
    case 'FETCHED_COUNTY_LOAN_LIMITS':
      return { ...state, submitting: false, submitFailed: false, submitSucceeded: true, countyLoanLimits: action.data ? action.data : [] }
    case 'POSTED_ESTIMATE':
      return { ...state, submitting: false, submitFailed: false, submitSucceeded: true, guarantyAmount: action.data.guarantyAmount, guarantyPercent: action.data.guarantyPercent }
    default:
      return state
  }
}

export default guarantyCalculatorReducer