/**
 * Creates a redux-form (and other form library) compliant error object from our error responses generated by ErrorController
 * 
 * @author Alex Clary
 * 
 * @param {} lgyErrorResponse 
 */
export const convertLgyErrorResponseToFormErrorObject = (lgyErrorResponse) => {
  console.log('Raw error response is ', lgyErrorResponse)
  if (!lgyErrorResponse || !lgyErrorResponse.errors) {
    throw new Error('unable to parse response object')
  }
  const firstNonFieldError = lgyErrorResponse.errors.find(e => !e.field)
  const firstNonFieldErrorMessage = firstNonFieldError ? firstNonFieldError.message : null

  const formError = {
    _error: firstNonFieldErrorMessage
  }

  lgyErrorResponse.errors.forEach((el) => {
    if (el.field && el.field.length > 0) {
      formError[el.field] = el.message;
    }
  })
  console.log('Created form error object', formError)
  return formError
}