const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  announcements: []
}

const announcementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'POSTING_ANNOUNCEMENTS':
    case 'FETCHING_ANNOUNCEMENTS':
      return { ...state, isLoading: true, isError: false, isSuccess: false }
    case 'FETCHING_SINGLE_ANNOUNCEMENT':
      return { ...state, isLoading: true, isError: false, isSuccess: false }
    case 'POSTED_ANNOUNCEMENTS':
    case 'FETCHED_ANNOUNCEMENTS':
      return { ...state, isLoading: false, isError: false, isSuccess: true, announcements: action.data ? action.data : [] }
    case 'FETCHED_SINGLE_ANNOUNCEMENT':
      return { ...state, isLoading: false, isError: false, isSuccess: true, announcement: action.data }
    default:
      return state
  }
}

export default announcementsReducer