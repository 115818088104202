import { catchApiErrors, catchTimeoutError, addWebpackBackendNotFoundError } from 'app/gears/errorhandler/actions/errorActions'
import { getCookieValue } from 'app/actions/genericActionCreator'

export const catchCommonErrors = (dispatch, response, gearName) => {
  const contentType = response.headers.get('content-type')
  if (response.status === 504) {
    response.clone().text().then((text) => {
      if (text.startsWith('Error occured while trying to proxy to')) {
        dispatch(addWebpackBackendNotFoundError())
        throw new Error('Backend server isn\'t running!')
      } else {
        dispatch(catchApiErrors(response, gearName))
        throw new Error(response.error)
      }
    })
  } else if (response.status >= 500) {
    dispatch(catchApiErrors(response, gearName))
    throw new Error(response.error)
  } else if (response.status === 204) {
    return // happy path, no body
  } else if (contentType) {
    if (contentType.includes('application/json')) { // happy path
      return response.json()
    } else if (contentType.includes('text/html')) {
      response.clone().text().then((text) => {
        if (text.includes('VAAFI Login')) {
          dispatch({ type: 'USER_IS_TIMED_OUT' })
          dispatch(catchTimeoutError())
          throw new Error('User session timed out.')
        } else {
          dispatch(catchApiErrors(response, gearName))
          throw new TypeError('Content-type isn\'t JSON!')
        }
      })
    }
  } else {
    // Many times a 200 may have an empty response body, such as after a delete
    if (response.status >= 200 && response.status < 300) {
      return
    }
    dispatch(catchApiErrors(response, gearName))
    throw new TypeError('Content-type is an unknown format!')
  }
}

/**
 * Convenience method to make actions more readable by abstracting distracting chore code
 * 
 * @author Alex Clary
 * 
 * @param {*} jsonData the data you want to post to the server
 */
export const fetchPostingParams = (jsonData) => ({
  credentials: "include",
  method: "POST",
  body: JSON.stringify(jsonData),
  headers: new Headers({
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": getCookieValue("XSRF-TOKEN")
  })
})