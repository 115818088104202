import { combineReducers } from 'redux'
import { genericReducer } from 'app/reducers/genericReducerCreator'

export const parentWorkspaceReducer = combineReducers({
  appraiserProfile: genericReducer('APPRAISER_PROFILE'),
  pendingAssignments: genericReducer('PENDING_ASSIGNMENTS'),
  canceledAssignments72Hours: genericReducer('CANCELED_ASSIGNMENTS_72_HOURS'),
  canceledAssignments90Days: genericReducer('CANCELED_ASSIGNMENTS_90_DAYS'),
  allAssignments90Days: genericReducer('ALL_ASSIGNMENTS_90_DAYS'),
  assignmentsWithUploads30Days: genericReducer('ASSIGNMENTS_WITH_UPLOADS_30_DAYS'),
  unacceptedAssignments: genericReducer('UNACCEPTED_ASSIGNMENTS')
})

export default parentWorkspaceReducer
