/**
 * @prettier
 * @author Alexander Nagelberg
 * @since 24.1
 */
//@ts-check
import { AnyAction } from 'redux'
import { IRemediationInfoResponse } from '../types/IRemediationInfoResponse'

export interface IRemediationState {
  fetching: boolean
  posting: boolean
  posted: boolean
  error?: string
  success?: boolean
  data?: IRemediationInfoResponse
  successMessage?: string
}

export enum RemediationActionType {
  InitialStateRemediation = 'GET_INITIAL_STATE_REMEDIATION',
  PostingRemediation = 'POSTING_REMEDIATION',
  PostingRemediationError = 'ERROR_POSTING_REMEDIATION',
  FetchingLenderWorkspace = 'FETCHING_LENDER_WORKSPACE',
  FetchingRemediation = 'FETCHING_REMEDIATION',
  PostedRemediation = 'POSTED_REMEDIATION',
  FetchedRemediation = 'FETCHED_REMEDIATION'
}

export interface RemediationAction extends AnyAction {
  type: RemediationActionType
  data: IRemediationInfoResponse
}

const initialState: IRemediationState = {
  fetching: false,
  posting: false,
  posted: false,
  error: undefined
}

export const remediationReducer = (
  state = initialState,
  { type, data, error }: RemediationAction
): IRemediationState => {
  switch (type) {
    case RemediationActionType.InitialStateRemediation:
      return {
        ...state,
        success: undefined,
        error: undefined,
        successMessage: undefined
      }
    case RemediationActionType.PostingRemediation:
      return {
        ...state,
        posting: true,
        success: false,
        error: undefined
      }
    case RemediationActionType.PostingRemediationError:
      return {
        ...state,
        posting: true,
        success: false,
        ...error
      }
    case RemediationActionType.FetchingLenderWorkspace:
    case RemediationActionType.FetchingRemediation:
      return {
        ...state,
        fetching: true,
        success: false,
        error: undefined
      }
    case RemediationActionType.PostedRemediation:
      return {
        ...state,
        ...data,
        posting: false,
        success: true
      }
    case RemediationActionType.FetchedRemediation:
      return {
        ...state,
        data,
        fetching: false
      }
    default:
      return state
  }
}
