/**
 * @prettier
 * @author Alexander Nagelberg
 * @since <feature>
 */
//@ts-check
import { AnyAction } from 'redux'
import { IMortgageLoanNoteDTO } from '../types/IMortgageLoanNoteDTO'
import { IMortgageLoanNoteResponse } from '../types/IMortgageLoanNoteResponse'

export interface INotesState {
  error: boolean
  fetching: boolean
  posting: boolean
  success: boolean
  data?: IMortgageLoanNoteResponse
}

export enum NotesActionType {
  InitialStateNotes = 'GET_INITIAL_STATE_NOTES',
  FetchingNotes = 'FETCHING_NOTES',
  FetchedNotes = 'FETCHED_NOTES',
  PostingNotes = 'POSTING_NOTES',
  PostedNotes = 'POSTED_NOTES',
  PostedNote = 'POSTED_NOTE',
  PostingLenderWorkspace = 'POSTING_LENDER_WORKSPACE',
  FetchingLenderWorkspace = 'FETCHING_LENDER_WORKSPACE'
}

export interface NotesAction extends AnyAction {
  type: NotesActionType
  data: IMortgageLoanNoteResponse
  note?: IMortgageLoanNoteDTO
}

const initialState: INotesState = {
  fetching: false,
  error: false,
  posting: false,
  success: false
}

export const notesReducer = (state = initialState, action: NotesAction) => {
  switch (action.type) {
    case NotesActionType.InitialStateNotes:
      return {
        ...state,
        success: undefined,
        error: undefined
      }
    case NotesActionType.FetchingNotes:
      return {
        ...state,
        fetching: true,
        success: false,
        error: false
      }
    case NotesActionType.FetchedNotes:
      return {
        ...state,
        fetching: false,
        data: action.data
      }
    case NotesActionType.PostingNotes:
      return {
        ...state,
        posting: true,
        success: false,
        error: false
      }
    case NotesActionType.PostedNotes:
      return {
        ...state,
        posting: false,
        success: true,
        data: action.data
      }
    case NotesActionType.PostedNote:
      // Merge new note in to pages of results
      // currently this adds to the top of the existing page.
      const data: IMortgageLoanNoteResponse = state.data ?? {
        notes: [],
        page: 1,
        totalPages: 1,
        totalResults: 0,
        totalPerPage: 10
      }

      // If there are more notes than should be displayed, pop the last note
      if (data.notes.length === data.totalPerPage) {
        data.notes.pop()
        data.totalPages++
      }

      data.totalResults++
      data.notes = action.note ? [action.note, ...data.notes] : data.notes
      return {
        ...state,
        posting: false,
        success: true,
        data
      }
    default:
      return state
  }
}
