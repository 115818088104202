import { combineReducers } from 'redux';
import { genericReducer } from '../../../reducers/genericReducerCreator';

const parentProfileReducer = combineReducers({
  currentUserProfile: genericReducer('CURRENT_USER_PROFILE'),
  stationList: genericReducer('STATION_LIST'),
  updateUserAffiliations: genericReducer('UPDATE_USER_AFFILIATIONS'),
  adminList: genericReducer('ADMIN_LIST') //TODO: maybe migrate to a different reducer. This should be fine since only one request.
});

export default parentProfileReducer
