/**
 * Reducer for Guaranty Remittance API Information page
 *
 * @prettier
 * @author Alexander Nagelberg
 * @since 24.1
 */
//@ts-check
import { AnyAction } from 'redux'
import { genericReducer } from 'app/reducers/genericReducerCreator'
import { IInflightRemittance } from '../types/inflightremittance/IInflightRemittance'

export interface IRemittanceState {
  fetching: boolean
  posting: boolean
  error: boolean
  success: boolean
  remittance: Array<IInflightRemittance>
}

export enum RemittanceActionType {
  PostingLenderWorkspace = 'POSTING_LENDER_WORKSPACE',
  FetchingLenderWorkspace = 'FETCHING_LENDER_WORKSPACE',
  PostedRemittance = 'POSTED_REMITTANCE',
  FetchedRemittance = 'FETCHED_REMITTANCE',
  FetchedRemittanceInfo = 'FETCHED_REMITTANCE_INFO'
}

export interface IRemittanceAction extends AnyAction {
  type: RemittanceActionType
  data: Array<IInflightRemittance>
}

const initialState: IRemittanceState = {
  fetching: false,
  posting: false,
  error: false,
  success: false,
  remittance: []
}

export const apiInfoReducer = (state = initialState, action: IRemittanceAction) => {
  state = genericReducer('LENDER_WORKSPACE')(state, action)
  switch (action.type) {
    case RemittanceActionType.PostingLenderWorkspace:
      return {
        ...state,
        error: false,
        success: false
      }
    case RemittanceActionType.FetchingLenderWorkspace:
      return {
        ...state,
        ...action.data
      }
    case RemittanceActionType.FetchedRemittanceInfo: {
      return {
        ...state,
        remittance: action.data,
        fetching: false,
        success: true
      }
    }
    default:
      return state
  }
}
