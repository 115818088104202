import React, { lazy, Component, Suspense } from 'react'
import { connect } from 'react-redux'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import LgyLoadingIcon from 'app/components/util/lgy-loading-icon/LgyLoadingIcon'

import { fetchCurrentUser } from '../actions/currentUserActions'
import { fetchServerProperties, fetchTrainingUrl } from '../actions/serverPropertiesActions'

import { fetchAllStations } from '../gears/profile/actions/profileActions' //TODO: move this somewhere else?

// Conditionally import so that one doesn't re-style the other
const App = lazy(() => import('./App'))
const AppV2 = lazy(() => import('./AppV2'))

class AppContainer extends Component {

  componentDidMount() {
    this.props.fetchTrainingUrl()
    this.props.fetchServerProperties()
    this.props.fetchCurrentUser()
    this.props.fetchAllStations()
  }

  inIframe() {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  render() {
    return (
      // This used to render <App /> directly but wraps it for different themes
      <Suspense fallback={<LgyLoadingIcon />}>
        <BrowserRouter>
          <Switch>
            <Route path='/lgyhub/2'>
              <AppV2 {...this.props} inIframe={this.inIframe()} />
            </Route>
            <Route path='/lgyhub'>
              <App  {...this.props} inIframe={this.inIframe()} />
            </Route>
          </Switch>
        </BrowserRouter>
      </Suspense>
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser,
  serverProperties: state.serverProperties,
  trainingUrl: state.trainingUrl

})

const mapDispatchToProps = { fetchCurrentUser, fetchServerProperties, fetchAllStations, fetchTrainingUrl }

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
