let initialState = {
  search: {
    results: [],
    isError: false,
    isSearching: false,
    isSuccess: false,
    // place holder for pagination
    pageNumber: 1
  },
  // User detail to be worked out.
  users: {
    /*'617837': {
      username: 'BOB123',
      firstName: 'Bob',
      lastName: 'Green',
      ssn: '3222',
      accountStatusCode: 1,
      lastLoginDate: 1541095922434,
      events: [
        {
          time: 1541095922434,
          applicationName: 'WebLGY',
          procedure: 'Test Proc',
          performedBy: 'BOB123',
          resultCode: 1
        }
      ],
      isLoading: false,
      isError: false,
      isSuccess: false
  },*/
  }
}

// TODO: error states, etc. missing for various actions
const userAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_SEARCH':
      return { ...state, search: { ...state.search, isSearching: true, isError: false, isSuccess: false } }
    case 'USER_SEARCH_SUCCESS':
      return { ...state, search: { ...state.search, isSearching: false, isError: false, isSuccess: true, results: action.payload.searchResults } }
    case 'USER_SEARCH_FAILURE':
      return { ...state, search: { ...state.search, isSearching: false, isError: true, isSuccess: false, results: [], error: action.payload.error } }
    case 'FETCHED_USER_EVENTS':
      // Set the user.events but leave the rest of the user alone
      return { ...state, users: { ...state.users, [action.userId]: { ...state.users[action.userId], events: action.events } } }
    case 'UPDATED_USER': // when we update the user we get a current copy of the user back, so let's reduce that the same way we reduce fetching a user
    case 'FETCHED_USER':
      // Set the user but leave the user.events field alone
      return {
        ...state,
        users: {
          ...state.users,
          [action.user.id]: {
            ...action.user,
            events: state.users[action.user.id] ? state.users[action.user.id].events : null // if the user response comes before the event response, then dereferencing state.users[action.user.id].events will throw an exception
          }
        }
      }

    default:
      return state // Means we aren't changing the state
  }
}

export default userAdminReducer
