import { combineReducers } from 'redux'
import { genericReducer } from 'app/reducers/genericReducerCreator'

const initialState = {
    completedChunks: 0
}

const chunkUploadReducer = (state = initialState, action) => {
    state = genericReducer('CHUNK_UPLOAD')(state, action)
    switch (action.type) {
      case 'CHUNK_COMPLETED':
        return {
          ...state,
          completedChunks: state.completedChunks+1
        }
      case 'CHUNK_RESET':
        return {
          ...state,
          completedChunks: 0
        }
      case 'CHUNK_RESET_ERROR':
        return {
          ...state,
          error: false
        }  
      default:
        return state
    }
}

const initializeChunkedUploadsReducer = (state = {}, action) => {
  state = genericReducer('INIT_CHUNKED_UPLOAD')(state, action)
  switch (action.type) {
    case 'INIT_CHUNK_RESET_ERROR':
      return {
        ...state,
        error: false
      }
    default:
      return state
  }
}

function chunkedUploadPropertiesReducer(state = null, action) {
  switch (action.type) {
    // If we fetched the file-manager file upload properties successfully, put them into the properties slice
    case 'FETCHED_CHUNK_UPLOAD_PROPERTIES':
      return action.data
    default:
      return state
  }
}

export const chunkedUploadsReducer = combineReducers({
    initializeChunkedUploads: initializeChunkedUploadsReducer,
    uploadChunk: chunkUploadReducer,
    properties: chunkedUploadPropertiesReducer
})

export default chunkedUploadsReducer 
