import { combineReducers } from 'redux'
import { createReducer } from 'redux-plupload'
import { ActionTypes } from 'redux-plupload'
import { genericReducer } from 'app/reducers/genericReducerCreator'

const pluploadReducer = (handle) => (state, action) => {
  state = createReducer(handle)(state, action)
  switch (action.type) {
    case ActionTypes.FILES_ADDED: {
      return !action.payload[0].size && action.meta.uploader.handle === handle ? {
        ...state,
        error: 'File size error.'
      } : state
    }
    case ActionTypes.FILE_UPLOADED: {
      return action.meta.uploader.handle === handle ? 
      {
        ...state,
        responseMessage: JSON.parse(action.meta.response.response).message
      } : state
    }
    case ActionTypes.ERROR: {
      if (action.meta.uploader.handle === handle) {
        if (action.payload.status >= 400) {
          return { ...state, responseMessage: 'N', responseReason: JSON.parse(action.payload.response).errors[0].message } 
        } 
        return { ...state, error: action.payload.message } 
      }
      return state
    }
    default:
      return state
  }
}

const salesContractReducer = (state = {}, action) => {
  switch (action.type) {
    case 'INIT_SALES_CONTRACT': {
      return { ...state, responseMessage: 'N', responseReason: '' }
    }
    case 'SALES_CONTRACT_UPLOADED': {
      return {
        ...state,
        responseMessage: action.data.message
      } 
    }
    case 'SALES_CONTRACT_UPLOAD_ERROR': {
      return { ...state, responseMessage: 'N', responseReason: action.error.message }
    }
    default:
      return state
  }
}

export const parentAssignmentReducer = combineReducers({
  assignmentDetails: genericReducer('ASSIGNMENT_DETAILS'),
  assignmentCorrespondence: genericReducer('ASSIGNMENT_CORRESPONDENCE'),
  assignmentNotes: genericReducer('ASSIGNMENT_NOTES'),
  appraiserUploads: combineReducers({
    appraisalReport: pluploadReducer('appraisalReportUploader'),
    salesContract: salesContractReducer,
    otherCorrespondence: pluploadReducer('otherCorrespondenceUploader')
  }),
  tidewater: genericReducer('TIDEWATER'),
  noteUploads: genericReducer('POST_ASSIGNMENT_NOTES')
})

export default parentAssignmentReducer