const initialState = {
  /* By starting with the assumption that isLoading is true, we avoid the need for multiple null/undefined 
  checks in the initial moment after rendering but before the load jobs action has fired. I'm not sure yet 
  if this is a bad practice or pragmatic one. */
  isLoading: true,
  isError: false,
  isSuccess: false,
  isStartingNewJob: false,
  fetchedDate: null,
  jobs: []
}

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'POSTING_JOBS':
      return { ...state, isLoading: false, isStartingNewJob: true, isError: false, isSuccess: false }
    case 'FETCHING_JOBS':
      return { ...state, isLoading: true, isError: false, isSuccess: false }
    case 'FETCHING_JOB':
      return { ...state, [`${action.data.jobName}IsLoading`]: true }
    case 'POSTED_JOBS':
      return { ...state, isLoading: false, isError: false, isSuccess: true, jobs: action.data ? action.data : [], isStartingNewJob: false }
    case 'FETCHED_JOBS':
      return { ...state, isLoading: false, isError: false, isSuccess: true, jobs: action.data ? action.data : [], fetchedDate: new Date() }
    case 'FETCHED_JOB':
      return { ...state, [`${action.data.jobName}IsLoading`]: false, isError: false, isSuccess: true, jobs: action.data.jobs, fetchedDate: new Date() }
    case 'ERROR_FETCHING_JOBS':
    case 'ERROR_FETCHING_JOB':
    case 'ERROR_POSTING_JOBS':
      return { ...state, isLoading: false, isError: true, isSuccess: false }
    default:
      return state
  }
}

export default jobReducer