let initialState = {
  isFetching: false,
  isDeleting: false,
  isSaving: false,
  id: '',
  type: '',
  headline: '',
  byline: '',
  endTime: ''
}

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALERT_FETCHING':
      return { ...state, isFetching: true }
    case 'ALERT_FETCHED':
      return { ...state, isFetching: false }
    case 'ALERT_DELETING':
      return { ...state, isDeleting: true }
    case 'ALERT_DELETED':
      return { ...state, isDeleting: false, id: '', type: '', headline: '', byline: '', endTime: '' }
    case 'ALERT_SAVING':
      return { ...state, isSaving: true }
    case 'ALERT_SAVED': // You have to insert a T in the date string or IE won't interpret the dates correctly
      return { ...state, isSaving: false, id: action.alert.id, type: action.alert.type, headline: action.alert.headline, byline: action.alert.byline, endTime: action.alert.endTime ? action.alert.endTime.replace(" ", "T") : null }
    case 'ALERT_CHANGED':
      return { ...state, id: action.alert.id, type: action.alert.type, headline: action.alert.headline, byline: action.alert.byline, endTime: action.alert.endTime ? action.alert.endTime.replace(" ", "T") : null }
    default:
      return state // Means we aren't changing the state
  }
}

export default alertReducer
