/**
 * @prettier
 * @author Alexander Nagelberg
 *
 **/
import { genericReducer } from 'app/reducers/genericReducerCreator'

const initialState = {
  fetching: false,
  posting: false,
  error: false,
  success: false,
  filter: { from: null, to: null, status: '', failure: '' }
}

const fundingFeeReducer = (state = initialState, action) => {
  state = genericReducer('FUNDING_FEE')(state, action)
  switch (action.type) {
    // Modifies the behavior missing in the genericReducerCreator of resetting error
    case 'POSTING_FUNDING_FEE':
      return {
        ...state,
        error: false,
        success: false
      }
    case 'FETCHING_FUNDING_FEE':
      return {
        ...state,
        ...action.data
      }
    case 'CLEAR_FUNDING_FEE':
      return {
        ...state,
        ...initialState,
        ...action.data
      }
    case 'MERGE_FUNDING_FEE_REFUNDS':
      /** this merges the existing refunds with the new refunds in action.data
       * by converting array + array -> object -> array
       * so long as they contain the exact same contents (deep equality)*/
      return {
        ...genericReducer('FETCHED_FUNDING_FEE')(state, action),
        refunds: Object.values([...(state.refunds ?? []), ...action.data.refunds])
      }
    default:
      return state
  }
}

export default fundingFeeReducer
