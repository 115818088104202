/**
 * @prettier
 */
import { combineReducers } from 'redux'
import userAdminReducer from '../user/reducers/userAdminReducer'
import { genericReducer } from 'app/reducers/genericReducerCreator'
import { settingsReducer } from '../settings/reducers/settingsReducer'

const adminReducer = combineReducers({
  userAdmin: userAdminReducer,
  enterpriseSettings: genericReducer('ENTERPRISE_SETTINGS'),
  settings: settingsReducer
})

export default adminReducer
